/** Enumeration of all possible visual types */
export enum VisualFileType {
  RAW = 'RAW',
  RAW_THUMB = 'RAW_THUMB',
  RAW_WEB = 'RAW_WEB',
  POST = 'POST',
  POST_THUMB = 'POST_THUMB',
  POST_WEB = 'POST_WEB',
  POST_THUMB_WATERMARK = 'POST_THUMB_WATERMARK',
  POST_WEB_WATERMARK = 'POST_WEB_WATERMARK',
}

export const POST_WATERMARK: 'POST_WATERMARK' = 'POST_WATERMARK'

/** Removes the ['_THUMB', '_WEB', '_THUMB_WATERMARK', '_WEB_WATERMARK'] from visual types. Transfers types such as VisualFileType.RAW_THUMB to VisualFileType.RAW */
export const downloadVisualTypeToBaseType = {
  [VisualFileType.RAW]: VisualFileType.RAW,
  [VisualFileType.RAW_THUMB]: VisualFileType.RAW,
  [VisualFileType.RAW_WEB]: VisualFileType.RAW,
  [VisualFileType.POST]: VisualFileType.POST,
  [VisualFileType.POST_THUMB]: VisualFileType.POST,
  [VisualFileType.POST_WEB]: VisualFileType.POST,
  [VisualFileType.POST_THUMB_WATERMARK]: VisualFileType.POST,
  [VisualFileType.POST_WEB_WATERMARK]: VisualFileType.POST,
}

/** Describes upload or download manipulation with files */
export enum FileManipulationKey {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
}

/** Enum representing the status of a visual process. */
export enum VisualStatus {
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  CREATED = 'CREATED',
  ERROR = 'ERROR',
}
