import { useCallback, useMemo, useState } from 'react'
import { useGalleryAssignment, useGalleryDeal } from '../../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { AssignmentStage } from 'constants/assignment'
import { PRODUCTS_WITH_OPTIONAL_STAGING } from 'constants/product/stagingProductKinds'
import { Path } from 'constants/router'
import constate from 'constate'
import { useNavigate } from 'react-router'

export enum GalleryFilter {
  ALL = 'ALL',
  FAVORITES = 'FAVORITES',
  PURCHASED = 'PURCHASED',
}

export enum ToolBarType {
  SELECTION = 'SELECTION',
  DOWNLOAD = 'DOWNLOAD',
  EDITING = 'EDITING',
}

export enum CurrentActionType {
  DOWNLOAD = 'DOWNLOAD',
  EDITING = 'EDITING',
}

export const [ClientGalleryContextProvider, useClientGallery] = constate(({
  assignmentId,
}: {
  assignmentId: string
}) => {
  const navigate = useNavigate()

  const { logGalleryEvent } = useGalleryAssignment()
  const { stagingAssignments, dealAssignmentsForTab } = useGalleryDeal()
  const { product } = useGalleryAssignment()

  // Gallery Filter selection - All/Purchased
  const [filter, setFilterRow] = useState(GalleryFilter.ALL)
  const [toolBarType, setToolBarType] = useState(ToolBarType.SELECTION)
  const [isToolBarOpen, setIsToolBarOpen] = useState<boolean>(false)
  const [currentActionType, setCurrentActionType] = useState(CurrentActionType.DOWNLOAD)

  const setFilter = useCallback((filter: GalleryFilter) => {
    setFilterRow(filter)

    logGalleryEvent(AnalyticsEvent.USE_GALLERY_FILTER, { filter })
  }, [logGalleryEvent])

  // Enabling the client additional staging flow
  // Checking if there are deal assignments with additional staging.
  // Checking if any staging assignments are not at the 'VISUALS_SENT_TO_CLIENT' stage.
  // Verifying if the product kind is part of the products that have optional staging.
  const enableStagingFlow = useMemo(
    () => {
      return !!stagingAssignments.length
        && !!product?.kind
        && dealAssignmentsForTab.some((assignment) => PRODUCTS_WITH_OPTIONAL_STAGING.has(assignment.products[0].kind))
        && stagingAssignments.some((assignment) => assignment.stage !== AssignmentStage.VISUALS_SENT_TO_CLIENT)
        && PRODUCTS_WITH_OPTIONAL_STAGING.has(product.kind)
    },
    [product?.kind, stagingAssignments, dealAssignmentsForTab]
  )

  // Navigate to staging flow => current assignment will be base for selection of images (will break if navigated from non-photo assignment)
  const openStagingFlow = () => {
    navigate(Path.GALLERY_STAGING.replace(':id', assignmentId.toString()))
  }

  return {
    assignmentId,
    filter,
    setFilter,
    toolBarType,
    setToolBarType,
    isToolBarOpen,
    setIsToolBarOpen,
    currentActionType,
    setCurrentActionType,
    enableStagingFlow,
    openStagingFlow,
  }
})
