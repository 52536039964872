import { FC, useCallback } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { GalleryFilter, useClientGallery } from '../_main/contexts'
import { Stack, Typography } from '@mui/material'

import { CircleIcon } from 'components/common/CircleIcon'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { ReactComponent as OrderTypePhotoIcon } from 'assets/img/icons/order_type_photo.svg'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Custom hide filter tab for specific flows */
  hideTab?: GalleryFilter
}

/**
 * Client gallery purchased/all filters dropdown.
 * 
 * @example <ClientGalleryFiltersDropdown />
 */
export const ClientGalleryFiltersDropdown: FC<Props> = ({
  hideTab = undefined
}) => {
  const { filter, setFilter } = useClientGallery()
  const { t } = useTranslation(['gallery'])

  const filterIcon = useCallback((filter: GalleryFilter) => {
    switch (filter) {
      case GalleryFilter.FAVORITES:
        return <CircleIcon size="2rem" icon={<StarBorderIcon />} />
      case GalleryFilter.PURCHASED:
        return <CircleIcon size="2rem" icon={<ShoppingCartOutlinedIcon />} />
      case GalleryFilter.ALL:
      default:
        return <CircleIcon size="2rem" icon={<OrderTypePhotoIcon />} />
    }
  }, [])

  return (
    <MUIDropdown
      sx={{ minWidth: '20rem' }}
      button={(isOpen, action) => (
        <MUIButton
          size="sm"
          type="secondaryBorder"
          onClick={action}
        >

          <FilterListRoundedIcon fontSize="large" />

          <Typography variant="text-md" color={GRAY_900} fontWeight={600} sx={{ width: 'max-content' }}>
            {t('filter')}:
          </Typography>

          <Typography variant="text-md" color={GRAY_700} fontWeight={600}>
            {t(`filters.${filter.toLowerCase()}`)}
          </Typography>

        </MUIButton>
      )}
    >
      {Object.values(GalleryFilter).map((item) => {
        if (item === hideTab) return null

        return (
          <MUIDropdownItem key={item} onClick={() => setFilter(item)} sx={{ height: '4rem' }}>
            <Stack flexDirection="row" alignItems="center" gap=".8rem">

              {filterIcon(item)}

              <Typography variant="text-md" color={GRAY_900} fontWeight={500}>
                {t(`filters.${item.toLowerCase()}`)}
              </Typography>

            </Stack>
          </MUIDropdownItem>
        )
      })}
    </MUIDropdown>
  )
}
