import { GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { DotDivider } from 'components/common/DotDivider'
import { FeatureFlag } from 'utils/featureFlags'
import { GalleryInfoCard } from '../GalleryInfoCard/GalleryInfoCard.component'
import { LessThanRequiredVisualsReason } from 'models/assignment'
import { MUIButton } from 'components/common/MUIButton'
import { OrderCreator } from 'components/pages/Gallery/_main/ClientGallery/common/OrderCreator/OrderCreator.component'
import { OrderDetailAssignmentItem } from '../OrderDetailAssignmentItem'
import Stack from '@mui/material/Stack'
import { SystemMessage } from 'components/common/SystemMessage'
import { TFunction } from 'i18next'
import Typography from '@mui/material/Typography'
import styles from './ClientOrderDetail.module.sass'
import { useFlag } from '@unleash/proxy-client-react'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useTranslation } from 'react-i18next'

/** Displays a message for when there are fewer visuals than required used in the SystemMessage comment prop. */
const LessRequiredVisualsMessage = (
  { reason, comment, t }: { reason: LessThanRequiredVisualsReason, comment?: string, t: TFunction }
) => (
  <Stack>
    <Typography variant="text-sm" fontWeight={500} color={GRAY_900}>
      {t(`enums:less_than_required_visuals_reason.${reason}`)}
    </Typography>

    {comment && <Typography variant="text-sm" color={GRAY_700}> {`"${comment}"`}</Typography>}
  </Stack>
)

/**
 * @interface Props Input properties
 */
interface Props {
  /** OnClick action to open the order detail drawer */
  onOpenDrawer: () => void
}

/**
 * Gallery client order detail component
 * @example <ClientOrderDetail></ClientOrderDetail>
 */
export const ClientOrderDetail: React.FC<Props> = ({ onOpenDrawer }) => {
  const { t } = useTranslation(['gallery', 'enums'])
  const { dealData } = useGalleryDeal()
  const allowNewCTUpload = useFlag(FeatureFlag.ALLOW_NEW_CT_UPLOAD)

  return (
    <GalleryInfoCard alignItems='left'>
      <Stack className={styles.orderDetail}>

        {/** GENERAL ORDER INFO */}
        <Stack direction={{ xs: 'column', sm: 'row' }} paddingBottom={2} alignItems={{ xs: 'left', sm: 'center' }} justifyContent="space-between">
          <Stack paddingBottom={{ xs: '1rem', sm: 0 }}>
            {/** ORDER ID */}
            <Stack direction="row">
              <span className={styles.title}>{t('order_informations.order')}</span>
              <DotDivider />
              {dealData && <span className={styles.title}>{`#${dealData.id}`}</span>}
            </Stack>

            {/** ORDER CREATOR */}
            <OrderCreator verticalAlignment={false} />
          </Stack>

          <Stack>
            <MUIButton type='secondaryBorder' onClick={onOpenDrawer} variant='outlined'>{t('order_informations.see_order_details')}</MUIButton>
          </Stack>
        </Stack>

        {/** ASSIGNMENTS ITEMS */}
        <Stack gap="1.6rem" direction="column-reverse">
          {dealData?.missions.map((assignment) =>
            <OrderDetailAssignmentItem
              key={`assignment-${assignment.id}`}
              assignment={assignment}
              footerContent={assignment.lessThanRequiredVisualsReason && allowNewCTUpload &&
                <SystemMessage
                  variant="warning"
                  title={t('less_than_required_visuals_title')}
                  message={
                    <LessRequiredVisualsMessage
                      t={t}
                      reason={assignment.lessThanRequiredVisualsReason}
                      comment={assignment.lessThanRequiredVisualsReason ?? undefined}
                    />
                  }
                />
              }
            />
          )}
        </Stack>
      </Stack>
    </GalleryInfoCard>
  )
}
