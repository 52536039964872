import { PropertyTab, useClientProperty } from '../_main/contexts'
import React, { useMemo } from 'react'
import { useGalleryAssignment, useGalleryProduct, useGalleryVirtualVisit } from 'components/pages/Gallery/_main/contexts'

import { AssignmentStage } from 'constants/assignment'
import { ClientGalleryEmptyContent } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryEmptyContent'
import { ClientGalleryOrderTypeFilter } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryOrderTypeFilter'
import { ClientGalleryPhoto } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryPhoto'
import { ClientGalleryVirtualVisit } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryVirtualVisit'
import { PageContent } from 'components/common/Page/PageContent'
import { Path } from 'constants/router'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

/**
 * Client Property Gallery content.
 *
 * @example <ClientPropertyVisualsGallery />
 */
export const ClientPropertyVisualsGallery: React.FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()

  const { assignmentStage } = useGalleryAssignment()
  const { isVirtualVisit } = useGalleryProduct()
  const { virtualVisitID } = useGalleryVirtualVisit()
  const {
    propertyId,
    propertyAssignmentsData,
    handleTabChange,
    setActiveTab
  } = useClientProperty()

  const isVisualSendToClient = useMemo(() => assignmentStage === AssignmentStage.VISUALS_SENT_TO_CLIENT, [assignmentStage])

  return (
    <Stack>
      <Stack width="100%" alignItems="center" justifyContent="center" >
        <PageContent>
          {/* ASSIGNMENT TYPES FILTERS */}
          {!!propertyAssignmentsData &&
            <ClientGalleryOrderTypeFilter
              isNonDocumentProduct
              missions={propertyAssignmentsData}
              onClick={(selectedAssignmentId) => navigate(Path.PROPERTY_VISUALS_GALLERY_ASSIGNMENT.replace(':id', propertyId).replace(':assignmentId', selectedAssignmentId))}
            />
          }
        </PageContent>

        <Stack width="100%" marginTop="3rem" gap={4} display="flex" flexDirection="row" justifyContent="space-between">

          {/** ASSIGNMENT NOT DELIVERED TO CLIENT */}
          {!isVisualSendToClient && (
            <ClientGalleryEmptyContent
              title={t('client_gallery.empty')}
              buttonText={t('client_gallery.empty_action')}
              onButtonClick={() => {
                setActiveTab(PropertyTab.ORDERS)
                handleTabChange(PropertyTab.ORDERS)
              }}
            />
          )}

          {/** ASSIGNMENT DELIVERED TO CLIENT. Photo, staging, video... etc */}
          {isVisualSendToClient && <ClientGalleryPhoto />}

          {/** ASSIGNMENT DELIVERED TO CLIENT. Matterport - Virtual visit */}
          {isVirtualVisit && !!virtualVisitID && (<ClientGalleryVirtualVisit />)}

        </Stack>
      </Stack>
    </Stack>
  )
}
