import { BEIGE_700, BLUE_600, BLUE_700, GRAY_100, GRAY_300, GRAY_600, GRAY_700, GRAY_800, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { DownloadDropdownIcon, DownloadDropdownItem } from './DownloadDropdownIcon'
import { FC, ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Color } from 'constants/assets'
import EditIcon from '@mui/icons-material/Edit'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FeatureFlag } from 'utils/featureFlags'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUITooltip } from 'components/common/MUITooltip'
import { Nullable } from 'models/helpers'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { VisualClientDTO } from 'models/visuals'
import { VisualStatus } from 'constants/visual'
import classnames from 'classnames'
import styles from './GalleryImage.module.sass'
import { useFlag } from '@unleash/proxy-client-react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props
 */
interface Props {
  isLoading?: boolean
  /** Label of the image badge */
  label?: string
  /** The image to display */
  imageUrl?: string
  /** Error message of the image */
  errorMessage?: string
  /** Whether the image has error or not */
  isError?: boolean
  /** Whether the gallery image is selected */
  isSelected?: boolean
  /** Whether the gallery image is selectable or not */
  isSelectable?: boolean
  /** Whether the gallery image show select button when hover on image */
  isHoverSelectable?: boolean
  /** Whether the selection toggle button is disabled or not */
  isSelectionDisabled?: boolean
  /** Whether the gallery image is editable with Pintura Modal */
  isEditable?: boolean
  /** Whether the gallery image can be marked as favorite or not */
  isFavoriteSelectable?: boolean
  /** Whether the gallery image is marked as favorite or not */
  isFavorite?: boolean
  /** Whether the favorite button has effect when hover on it */
  isFavoriteHoverActive?: boolean
  /** Whether the favorite button has disabled clicking */
  isFavoriteDisabled?: boolean
  /** Whether the gallery image show download button when hover on image or not */
  isDownloadable?: boolean
  /** Custom component to display in the image box */
  children?: ReactNode
  /** Metadata of a visual */
  visualMetadata?: Nullable<VisualClientDTO>
  /** Status of the visual */
  status?: Nullable<VisualStatus>
  /** OnSelect action to be called */
  onSelect?: (e: React.MouseEvent) => void
  /** OnFavSelect action to be called */
  onFavSelect?: (e: React.MouseEvent) => void
  /** OnClick action to be called */
  onClick?: (e: React.MouseEvent) => void
  /** Callback for clicking on the floor planner link */
  onFloorPlannerLinkClick?: (e: React.MouseEvent) => void
  /** Callback for clicking on the edit with Pintura button */
  onEditWithPinturaClick?: () => void
  /** Callback for clicking on one of the drowndown options */
  onDownloadOptionClick?: (itemKey: DownloadDropdownItem) => void
}

/**
 * Gallery image component
 * 
 * @example <GalleryImage />
 */
export const GalleryImage: FC<Props> = ({
  isLoading = false,
  label,
  imageUrl,
  errorMessage,
  isError,
  isSelected,
  isSelectable = false,
  isHoverSelectable = false,
  isFavoriteSelectable = false,
  isFavorite = false,
  isFavoriteHoverActive,
  isFavoriteDisabled = false,
  isEditable = false,
  isDownloadable = false,
  children,
  isSelectionDisabled,
  visualMetadata,
  status = null,
  onSelect,
  onFavSelect,
  onClick,
  onFloorPlannerLinkClick,
  onEditWithPinturaClick,
  onDownloadOptionClick,
}) => {
  const { t } = useTranslation(['gallery', 'visual_pintura_editing'])
  const isBetaSelfEditingCard = useFlag(FeatureFlag.BETA_SELF_EDIT_WIDGET)

  const { hasUserActiveSubscription } = useUserData()

  const allowPinturaEditing = useFlag(FeatureFlag.ALLOW_PINTURA_EDITING)
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const isSelfEditedByClient = visualMetadata?.selfEdited

  let style: React.CSSProperties = {}

  if (imageUrl) {
    style.backgroundImage = `url('${imageUrl}')`
  }

  const onFavoriteClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()

    if (!isFavoriteDisabled) {
      onFavSelect?.(e)
    }
  }

  const floorPlannerLink = useMemo(() => {
    return visualMetadata?.floorPlanSelfEditUrl
  }, [visualMetadata])

  const isProcessingImage = useMemo(() => status === VisualStatus.PROCESSING, [status])

  return (

    <div
      className={classnames(styles.galleryImage,
        {
          [styles.selected]: isSelected,
          [styles.hoverSelectButton]: isHoverSelectable,
          [styles.hoverFavoriteButton]: isFavoriteHoverActive,
          [styles.hoverEditButton]: !!floorPlannerLink,
          [styles.hoverEditVisualButton]: isEditable,
          [styles.hoverDownloadableButton]: isDownloadable,
          [styles.clickable]: !!onClick,
          [styles.error]: isError,
          [styles.mobile]: isMobileView,
          [styles.processing]: isProcessingImage,
        })
      }
      style={style}
      onClick={(e) => !isProcessingImage && onClick?.(e)}
    >

      {isLoading &&
        <CircularProgress
          variant="indeterminate"
          size={30}
          thickness={5}
          sx={{
            color: Color.GRAY_TEXT,
            borderRadius: '50%',
            // Background ring for unfilled progress
            boxShadow: `inset 0 0 0px 3px ${BEIGE_700}`
          }}
        />
      }
      {/** TOP ICONS */}
      <Stack direction="row" gap={0.8} position="absolute" sx={{ top: '0.8rem', left: 0, right: 0, padding: '0 0.8rem' }}>

        {isSelectable &&
          <span
            className={classnames(styles.iconButton, styles.selectButton, { [styles.selected]: isSelected, [styles.disabled]: isSelectionDisabled })}
            onClick={e => {
              e.stopPropagation()
              if (!isSelectionDisabled && !isProcessingImage) onSelect?.(e)
            }}>
            <CheckRoundedIcon className={styles.checkIcon} sx={{ color: BLUE_600 }} />
          </span>
        }

        <Stack direction="row" gap={0.8} sx={{ marginLeft: 'auto' }}>
          {allowPinturaEditing && isEditable &&
            <span
              className={classnames(styles.iconButton, styles.editVisualButton)}
              onClick={(e) => {
                e.stopPropagation()
                if (!isProcessingImage) onEditWithPinturaClick?.()
              }}
            >
              <MUITooltip
                content={
                  isEditable &&
                  (!hasUserActiveSubscription && !isBetaSelfEditingCard) &&
                  t('visual_pintura_editing:non_subscribers_tooltip')
                }
              >
                <EditOutlinedIcon className={styles.favIcon} sx={{ color: GRAY_800 }} />
              </MUITooltip>
            </span>
          }

          {isDownloadable &&
            <span className={classnames(styles.iconButton, styles.downloadButton)} onClick={(e) => e.stopPropagation()}>
              <DownloadDropdownIcon
                onDownloadItemClick={onDownloadOptionClick}
              />
            </span>
          }

          {isFavoriteSelectable &&
            <span
              className={classnames(styles.iconButton, styles.favButton, { [styles.favSelected]: isFavorite, [styles.favDisabled]: isFavoriteDisabled })}
              onClick={e => {
                e.stopPropagation()
                if (!isFavoriteDisabled && !isProcessingImage) onFavoriteClick(e)
              }}
            >
              {isFavorite ?
                <StarIcon className={styles.favIcon} sx={{ color: BLUE_600 }} />
                :
                <StarBorderIcon className={styles.favIcon} sx={{ color: isFavoriteDisabled ? GRAY_600 : BLUE_600 }} />
              }
            </span>
          }

          {!!floorPlannerLink &&
            <a
              className={classnames(styles.iconButton, styles.editButton)}
              onClick={e => onFloorPlannerLinkClick?.(e)}
              href={floorPlannerLink}
              target="_blank" rel="noreferrer"
              title={t('edit_floor_plan')}
            >
              <EditIcon className={styles.editIcon} sx={{ color: BLUE_600 }} />
            </a>
          }
        </Stack>

      </Stack>

      {!!label && !isProcessingImage &&
        <Box position="absolute" left=".8rem" bottom=".8rem">

          <MUIBadge label={label} size="sm" color="gray" />

          {/* Whether Client used Pintura to self edit visual */}
          {isSelfEditedByClient &&
            <MUIBadge size="md" color="darkGray" sx={{ marginLeft: 0.4 }} icon={<AutoFixHighOutlinedIcon />} />
          }

        </Box>
      }

      {/* TODO: New error message for load/upload/download image will be in another task, can handle it like <ErrorMessage /> component. */}
      {isError && !!errorMessage && <Trans className={styles.errorMsg} parent="p">{errorMessage}</Trans>}


      {isProcessingImage &&
        <Box position="absolute" left=".8rem" bottom=".8rem">
          <BorderBoxWrapper
            borderColor={GRAY_300}
            backgroundColor={GRAY_100}
            sx={{
              opacity: 1,
              gap: '0.4rem',
              display: 'flex',
              alignItems: 'center',
              padding: '0.2rem 0.6rem',
            }}
          >

            <CircularProgress size={14} thickness={4} sx={{ color: BLUE_700 }} className={styles.processingTag} />

            <Typography variant="text-xs" fontWeight={500} color={GRAY_700}>
              {t('image_processing')}
            </Typography>

          </BorderBoxWrapper>
        </Box>
      }

      {children}

    </div>
  )
}
