export enum FeatureFlag {
  ALLOW_PROPERTY_KEYWORDS = 'allow-property-keywords', // By default ENABLED for both envs!! This is used on Visuals Marketing AI feature as a quick security flag in case someone will try to do XSS attack
  ALLOW_PROPERTY_FREETEXT = 'allow-marketing-property-freetext',
  ALLOW_REPEATED_UPSELL_PAYMENT = 'allow-repeated-upsell-payment', // Disabled on both envs feature not fully developed! If enabled there will be possibility to retry failed stripe payment in upsell payment popup
  IS_SITE_IN_MAINTANANCE = 'is-site-in-maintenance',   // Prevents using the site, therefore false on both envs in Gitlab
  ALLOW_RESUMABLE_UPLOADS = 'allow-resumable-uploads',
  ALLOW_PROPERTY_SELECTION_STEP = 'allow-property-selection-step',
  ALLOW_PINTURA_EDITING = 'allow-pintura-editing',
  BETA_SELF_EDIT_WIDGET = 'beta-self-edit-widget',
  ENERGY_CERT_MOOVIN_FIXES = 'energy-cert-moovin-fixes',
  ALLOW_SECOND_MAP_PIN = 'allow-second-map-pin',
  ALLOW_MANUAL_ADDRESS = 'allow-manual-address',
  ALLOW_EDITING_TOOL_NON_SUBSCRIBED_USERS = 'allow-editing-tool-non-subscribed-users', // Disabled on both envs feature not fully developed, remove after BKBN-2475 task is done
  ALLOW_GROUPED_CT_ASSIGNMENTS = 'allow-ct-grouped-assignments',
  ALLOW_CLIENT_CREATE_REQUEST_DOCUMENT_SENT = 'allow-client-create-request-documents-sent', // Waiting for BE to implement the feature on their side
  ALLOW_NEW_MAP_LIB = 'allow-new-map-lib',
  ALLOW_CLIENT_CHANGE_REQUEST = 'allow-client-change-request',
  ALLOW_NEW_CT_UPLOAD = 'require-reason-for-less-than-required-visuals', // Includes new CT upload flow with reason for less than required visuals
  ALLOW_REDESIGN_VISUALS_FILTER = 'allow-redesign-visuals-filter',
}
