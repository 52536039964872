import { useGalleryAssignment, useGalleryProduct, useGalleryVisuals, useGalleryVisualsMeta } from '../../contexts'

import { ClientGalleryFilters } from '../ClientGalleryFilters'
import { ClientGalleryFiltersDropdown } from '../ClientGalleryFiltersDropdown'
import { ClientGallerySendRequest } from '../ClientGallerySendRequest'
import { FC } from 'react'
import { FeatureFlag } from 'utils/featureFlags'
import { GalleryFilter } from '../_main/contexts'
import { GallerySection } from 'components/common/Gallery/GallerySection'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** JSX elements that we render in section as unpurchased */
  unPurchasedVisuals: JSX.Element
}

/**
 * Client gallery photo section for not purchased visuals only.
 * 
 * @example <ClientGalleryPhotoSectionNotPurchasedAny />
 */
export const ClientGalleryPhotoSectionNotPurchasedAny: FC<Props> = ({ unPurchasedVisuals }) => {
  const { t } = useTranslation(['gallery'])
  const allowClientChangeRequest = useFlag(FeatureFlag.ALLOW_CLIENT_CHANGE_REQUEST)
  const allowRedesignFilter = useFlag(FeatureFlag.ALLOW_REDESIGN_VISUALS_FILTER)

  const { favoritedVisuals } = useGalleryVisuals()
  const { isVideo } = useGalleryProduct()
  const { isFeedbackButtonAllowed } = useGalleryAssignment()
  const {
    purchasedVisualsCount,
    unPurchasedVisualsCount
  } = useGalleryVisualsMeta()

  return (
    <>

      {allowRedesignFilter ?
        <ClientGalleryFiltersDropdown hideTab={GalleryFilter.PURCHASED} />
        :
        <ClientGalleryFilters
          purchasedCount={purchasedVisualsCount}
          totalCount={purchasedVisualsCount + unPurchasedVisualsCount}
          favoritesCount={favoritedVisuals.size ?? 0}
          hideTab={GalleryFilter.PURCHASED} // in FLOW A (unselected visuals) we want show only filter All & Favourites
        />
      }

      <GallerySection
        title={t('visuals_ready')}
        subTitle={t('visuals_ready_description')}
        action={!isVideo && isFeedbackButtonAllowed && allowClientChangeRequest && <ClientGallerySendRequest />}
      >
        {unPurchasedVisuals}
      </GallerySection>

    </>
  )
}
