import { useGalleryVisuals, useGalleryVisualsMeta } from '../../contexts'

import { ClientGalleryActionsPanel } from '../ClientGalleryActionsPanel'
import { ClientGalleryFilters } from '../ClientGalleryFilters'
import { ClientGalleryFiltersDropdown } from '../ClientGalleryFiltersDropdown'
import { FC } from 'react'
import { FeatureFlag } from 'utils/featureFlags'
import Stack from '@mui/material/Stack'
import { useFlag } from '@unleash/proxy-client-react'

/**
 * Client gallery photo section Action Panel and Filters
 * 
 * @example <ClientGalleryPhotoSectionActionFilterPanels />
 */
export const ClientGalleryPhotoSectionActionFilterPanels: FC = () => {

  const allowRedesignFilter = useFlag(FeatureFlag.ALLOW_REDESIGN_VISUALS_FILTER)

  const { favoritedVisuals } = useGalleryVisuals()

  const {
    purchasedVisualsCount,
    unPurchasedVisualsCount,
    purchasedVisualsExist
  } = useGalleryVisualsMeta()

  return (
    <Stack
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent={purchasedVisualsExist && !allowRedesignFilter ? 'space-between' : 'flex-end'}
      alignItems={{ xs: 'start', md: 'center' }}
      gap="1.5rem"
    >

      {allowRedesignFilter ?
        <ClientGalleryFiltersDropdown />
        :
        <ClientGalleryFilters purchasedCount={purchasedVisualsCount} totalCount={purchasedVisualsCount + unPurchasedVisualsCount} favoritesCount={favoritedVisuals.size ?? 0} />
      }

      <ClientGalleryActionsPanel />

    </Stack>
  )
}
